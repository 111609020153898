import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ECodesService } from '../services';
import { ECodeTypeEnum, ICity, IECodeDataItem, IState } from '../e-codes';
import { IListResponseData } from '../abstract';

export class ECodesStateFetchStates {
  public static readonly type = '[ECodesState] fetch states';
}

export class ECodesStateFetchCities {
  public static readonly type = '[ECodesState] fetch cities';

  constructor(public readonly payload: { stateId: number }) {}
}

export class ECodesStateFetchOnlineECodes {
  public static readonly type = '[ECodesState] fetch online eCodes';
}

export class ECodesStateFetchCityECodes {
  public static readonly type = '[ECodesState] fetch city eCodes';

  constructor(public readonly payload: { city: string }) {}
}

export class ECodesStateFetchMapECodes {
  public static readonly type = '[ECodesState] fetch map eCodes';

  constructor(public readonly payload: { lat: string; long: string }) {}
}

export interface ECodesStateModel {
  loading: boolean;
  states: IState[];
  cities: {
    [stateId: number]: ICity[];
  };
  cityECodes: {
    [city: string]: IECodeDataItem[];
  };
  onlineECodes: IECodeDataItem[];
  mapECodes: IECodeDataItem[];
}

@State<ECodesStateModel>({
  name: 'eCodes',
  defaults: {
    loading: false,
    states: null,
    cities: {},
    cityECodes: {},
    onlineECodes: null,
    mapECodes: null,
  },
})
@Injectable()
export class ECodesState {
  public constructor(private readonly _eCodesService: ECodesService) {}

  @Selector()
  public static loading({ loading }: ECodesStateModel): boolean {
    return loading;
  }

  @Selector()
  public static onlineECodes({ onlineECodes }: ECodesStateModel): IECodeDataItem[] {
    return onlineECodes;
  }

  @Selector()
  public static mapECodes({ mapECodes }: ECodesStateModel): IECodeDataItem[] {
    return mapECodes;
  }

  @Selector()
  public static states({ states }: ECodesStateModel): IState[] {
    return states;
  }

  @Selector()
  public static cities({
    cities,
  }: ECodesStateModel): {
    [stateId: number]: ICity[];
  } {
    return cities;
  }

  @Selector()
  public static cityECodes({
    cityECodes,
  }: ECodesStateModel): {
    [city: string]: IECodeDataItem[];
  } {
    return cityECodes;
  }

  @Action(ECodesStateFetchStates)
  public fetchStates({ patchState, getState }: StateContext<ECodesStateModel>): Observable<unknown[]> {
    const { states } = getState();
    if (states) {
      return of(states);
    }
    patchState({ loading: true });
    return this._eCodesService.getStates().pipe(
      tap((states) => {
        patchState({ states, loading: false });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
    );
  }

  @Action(ECodesStateFetchCities)
  public fetchCities(
    { patchState, getState }: StateContext<ECodesStateModel>,
    { payload: { stateId } }: ECodesStateFetchCities,
  ): Observable<ICity[]> {
    const { cities } = getState();
    if (cities[stateId]) {
      return of(cities[stateId]);
    }
    patchState({ loading: true });
    return this._eCodesService.getCities(stateId).pipe(
      tap((_cities) => {
        patchState({
          cities: {
            ...cities,
            [stateId]: _cities,
          },
          loading: false,
        });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
    );
  }

  @Action(ECodesStateFetchCityECodes)
  public fetchCitiesECodes(
    { patchState, getState }: StateContext<ECodesStateModel>,
    { payload: { city } }: ECodesStateFetchCityECodes,
  ): Observable<IECodeDataItem[]> {
    const { cityECodes } = getState();
    if (cityECodes[city]) {
      return of(cityECodes[city]);
    }
    patchState({ loading: true });
    return this._eCodesService
      .getECodes({
        page: 1,
        type: ECodeTypeEnum.STATIONARY,
        city,
        perPage: 99999,
        active: '1',
      })
      .pipe(
        map((responseData) => [
          ...responseData.data,
          {
            id: -1,
            name: 'Inne',
            city: null,
            groups: null,
            lat: null,
            long: null,
            network: null,
            state: null,
            street: null,
            tps: null,
            type: null,
            zip_code: null,
          },
        ]),
        tap((_cityECodes) => {
          patchState({
            cityECodes: {
              ...cityECodes,
              [city]: _cityECodes,
            },
            loading: false,
          });
        }),
        catchError((err) => {
          patchState({ loading: false });
          return throwError(err);
        }),
      );
  }

  @Action(ECodesStateFetchOnlineECodes)
  public fetchOnlineECodes({ patchState, getState }: StateContext<ECodesStateModel>): Observable<IECodeDataItem[]> {
    const { onlineECodes } = getState();
    if (onlineECodes) {
      return of(onlineECodes);
    }
    patchState({ loading: true });
    return this._eCodesService
      .getECodes({
        page: 1,
        type: ECodeTypeEnum.WWW,
        perPage: 99999,
        active: '1',
      })
      .pipe(
        map((responseData) => [
          ...responseData.data,
          {
            id: -1,
            name: 'Inne',
            city: null,
            groups: null,
            lat: null,
            long: null,
            network: null,
            state: null,
            street: null,
            tps: null,
            type: null,
            zip_code: null,
          },
        ]),
        tap((_onlineECodes) => {
          patchState({ onlineECodes: _onlineECodes, loading: false });
        }),
        catchError((err) => {
          patchState({ loading: false });
          return throwError(err);
        }),
      );
  }

  @Action(ECodesStateFetchMapECodes)
  public fetchMapECodes(
    { patchState }: StateContext<ECodesStateModel>,
    { payload: { lat, long } }: ECodesStateFetchMapECodes,
  ): Observable<IListResponseData<IECodeDataItem>> {
    patchState({ loading: true });
    return this._eCodesService
      .getECodes({
        page: 1,
        type: ECodeTypeEnum.STATIONARY,
        perPage: 99999,
        lat,
        long,
        active: '1',
        distance: 25,
      })
      .pipe(
        tap((mapECodes) => {
          patchState({ mapECodes: mapECodes.data, loading: false });
        }),
        catchError((err) => {
          patchState({ loading: false });
          return throwError(err);
        }),
      );
  }
}
