import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from './tokens';

@Injectable({
  providedIn: 'root',
})
export class GoogleYoutubeService {
  public readonly ID_GY_SCRIPT = 'GYscript';

  private _isLoaded = false;
  private readonly _renderer2: Renderer2;

  constructor(
    @Inject(DOCUMENT)
    private readonly _document: Document,
    private readonly _rendererFactory: RendererFactory2,
  ) {
    this._renderer2 = this._rendererFactory.createRenderer(null, null);
  }

  private _scriptExists(): boolean {
    const script = this._document.getElementById(this.ID_GY_SCRIPT);
    return !!script;
  }

  public addScriptToDom(): Promise<unknown> {
    return new Promise((resolve, reject) => {
      if (this._scriptExists()) {
        this._isLoaded = true;
        resolve(true);
        return;
      }
      const gyScript = this._renderer2.createElement('script');
      gyScript.src = 'https://www.youtube.com/iframe_api';
      gyScript.type = 'text/javascript';
      gyScript.id = this.ID_GY_SCRIPT;
      gyScript.defer = true;
      gyScript.async = true;
      gyScript.onload = resolve;
      gyScript.onerror = reject;
      gyScript.src = `https://www.youtube.com/iframe_api`;
      this._renderer2.appendChild(this._document.head, gyScript);
    });
  }
}
