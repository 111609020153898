import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { getDocument, getWindow } from 'ssr-window';

export class Utils {
  public static pick<T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
    return keys.reduce((acc, k) => ((acc[k] = obj[k]), acc), {} as Pick<T, K>);
  }

  public static scrollToError(): void {
    const elementList = getDocument().querySelectorAll('.ng-invalid:not(div):not(form):not(table):not(tr)');
    if (elementList && elementList?.length > 0) {
      const element = elementList[0] as HTMLElement;
      const headerOffset = 150;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition - headerOffset + window.pageYOffset;
      window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    }
  }

  public static isPasswordErrorExists(abstractControl: AbstractControl): boolean {
    return (
      abstractControl.hasError('hasLower') ||
      abstractControl.hasError('hasLower') ||
      abstractControl.hasError('hasUpper') ||
      abstractControl.hasError('hasNumber') ||
      abstractControl.hasError('hasSpecial') ||
      abstractControl.hasError('minlength')
    );
  }

  public static markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  public static getValidatorErrorMessage(validatorName: string, validatorValue?: unknown): string | undefined {
    const passwordsMessage =
      'Hasło powinno zawierać co najmniej 8 znaków, w tym małą i dużą literę, znak specjalny i cyfrę.';
    const config = {
      url: 'Podaj poprawny adres URL.',
      ibanInvalid: 'Podaj poprawny numer konta bankowego.',
      notValidPolishPhoneNumber: 'Proszę wprowadzić polski numer telefonu.',
      validatePhoneNumber: 'Podany numer telefonu jest niepoprawny.',
      serialIsNotCorrectForRegisteredProduct:
        'Podany numer seryjny/fabryczny jest nieprawidłowy dla zarejestrowanego produktu.',
      serialIsNotValid: 'Podany numer seryjny/fabryczny jest niepoprawny.',
      serialExist: 'Podany numer seryjny/fabryczny już istnieje.',
      serialExistBesidePromotion: 'Podany nr seryjny jest już zarejestrowany w Twoim koncie Moja Amica.',
      productNotAvailable: 'Produkt niedostępny w tej promocji.',
      cardNumber: 'Podaj poprawny numer karty.',
      mask: 'Podaj wartość w poprawnym formacie.',
      nip: 'Podaj poprawny numer NIP.',
      pesel: 'Podaj poprawny numer pesel.',
      email: 'Podaj prawidłowy adres e-mail.',
      required: 'To pole jest obowiązkowe.',
      maxlength: `Maksymalna długość pola to ${validatorValue['requiredLength']}.`,
      minlength: `Minimalna długość pola to ${validatorValue['requiredLength']}.`,
      matchOther: `Podane hasła muszą być identyczne.`,
      hasUpper: passwordsMessage,
      hasSpecial: passwordsMessage,
      hasNumber: passwordsMessage,
      hasLower: passwordsMessage,
    };
    return config[validatorName];
  }

  public static setErrorsAndScrollToThem(errorsObj: any, form: UntypedFormGroup | UntypedFormArray): void {
    if (typeof errorsObj === 'string') {
      return;
    }

    if (Array.isArray(errorsObj)) {
      for (let i = 0; i < errorsObj.length; i++) {
        this.setErrorsAndScrollToThem(errorsObj[i]['children'], form?.controls[i]);
      }
      return;
    }

    for (const key in errorsObj) {
      if (!Object.prototype.hasOwnProperty.call(errorsObj, key)) {
        continue;
      }

      // if has nested values
      if (errorsObj[key]['children'] && form.controls[key]) {
        const formGroup = form.controls[key];
        this.setErrorsAndScrollToThem(errorsObj[key]['children'], formGroup);
        continue;
      }

      if (typeof errorsObj[key] === 'object' && errorsObj[key] !== null && !Array.isArray(errorsObj[key])) {
        const errors = {};
        for (const err in errorsObj[key]) {
          if (Object.prototype.hasOwnProperty.call(errorsObj[key], err)) {
            errors[err] = errorsObj[key][err];
          }
        }
        if (Object.keys(errors).length > 0 && errors['errors'] && form.get(key)) {
          form.get(key).setErrors({ backend: { errors: errors['errors'][0] } });
        }
      } else if (form.get(key)) {
        form.get(key).setErrors({ backend: [errorsObj[key]] });
      }
    }

    setTimeout(() => {
      Utils.scrollToError();
    }, 250);
  }

  public static scrollToTop(): void {
    getWindow().scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}
