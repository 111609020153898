export const environment = {
  production: true,
  api: 'https://promocjeb2c.amica.pl/api/',
  uploadFilesLocation: 'https://promocjeb2c.amica.pl/',
  amcUrl: 'https://moja.amica.pl',
  confirmationLink: 'https://moja.amica.pl/#/potwierdzenie-konta/',
  bearerToken: '2ze8u8w1fk000ws80wo48k404ww4wc48k84c8k0gos0g4oo08g1m6sac5e9r8k0wsgg0484ckkwgos4g44co0k8gccsg84csogg8',
  gtm: 'GTM-KJJG3V',
  promotionId: 53,
  surveyId: 23,
  googleMapsKey: 'AIzaSyDhZef0kwLhTP0KGxExOpHgKx8hqQV1Otw',
};
