export enum QuestionTypeEnum {
  /**
   * Boolean question (false/true)
   */
  YES_NO = 5,
  /**
   * Multi answers question (checkboxes)
   * valid answers - array with question answers
   */
  MULTIPLE_CHOICE = 3,
  /**
   * Multi answers question - only one answer (radiobutton)
   * valid answer - string with question answer
   */
  ONE_CHOICE = 4,
  /**
   * Free text question (textarea)
   * valid answer - any text
   */
  FREE_TEXT = 2,
  /**
   * scale question
   * valid answer is integer from 1 to 5
   */
  SCALE = 1,
  /**
   * predefined question about sex
   * valid answer - (f|m)
   */
  SEX = 10,
  /**
   * predefined question about education
   * valid answer ID - (1-nieokreślone,2-podstawowe,3-średnie,4-wyższe)
   */
  EDUCATION = 11,
  /**
   * predefined question about metro size
   * valid answer ID - (1-nieokreślone,2-do 10 tysięcy mieszkańców,3-10-50 tysięcy mieszkańców,4-50-100 tysięcy mieszkańców,5-100-500 tysięcy mieszkańców,6-powyżej 500 tysięcy mieszkańców,7-wieś)
   */
  METRO_SIZE = 12,
  /**
   * Predefined e-mail address field (for sending poll emails)
   */
  EMAIL = 20,
}
