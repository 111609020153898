import { Inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { concatMap, finalize, tap } from 'rxjs/operators';
import { UserStateSetJwtToken, UserStateSetUserData } from '../states/user.state';
import { LoginService, SERVICES_CONFIRMATION_LINK, UserService } from '../services';
import { INgxsFormModel } from '../abstract';
import { IUser } from '../user';
import { ILoginDto, ILoginResponse } from '../login';
import { SyneriseService } from '../services/synerise.service';
import { ISynLoginDto, SyneriseActionEnum } from '../synerise';

export class LoginStateLogin {
  public static readonly type = '[LoginState] login';
}

export interface LoginStateModel {
  loading: boolean;
  form: INgxsFormModel<{
    username: string;
    password: string;
  }>;
}

@State<LoginStateModel>({
  name: 'login',
  defaults: {
    loading: false,
    form: null,
  },
})
@Injectable()
export class LoginState {
  public constructor(
    @Inject(SERVICES_CONFIRMATION_LINK)
    private readonly _confirmationLink: string,
    private readonly _loginService: LoginService,
    private readonly _userService: UserService,
    private readonly _syneriseService: SyneriseService,
  ) {}

  @Selector()
  public static loading({ loading }: LoginStateModel): boolean {
    return loading;
  }

  @Action(LoginStateLogin)
  public login(state: StateContext<LoginStateModel>): Observable<IUser> {
    const { dispatch, getState, patchState } = state;
    const {
      form: { model },
    } = getState();
    patchState({ loading: true });
    const loginDto: ILoginDto = { ...model, confirmation_link: this._confirmationLink };
    const _sendSynFormData = () => {
      const synLoginDto: ISynLoginDto = {
        email: loginDto.username,
      };
      this._syneriseService.sendFormData(SyneriseActionEnum.LOGIN, synLoginDto);
    };
    return this._loginService.login(loginDto).pipe(
      concatMap(({ token, expired_at }: ILoginResponse) => {
        _sendSynFormData();
        dispatch(new UserStateSetJwtToken({ token, tokenExpiredAt: expired_at }));
        return this._userService.getMe(token).pipe(
          tap((user: IUser) => {
            dispatch(new UserStateSetUserData({ user }));
          }),
          finalize(() => {
            patchState({ loading: false });
          }),
        );
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }
}
