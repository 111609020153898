import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserState } from '@am-canteens/data';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(private readonly _store: Store) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._canActivate(route, state);
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._canActivate(route, state);
  }

  private _canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const { token } = this._store.selectSnapshot(UserState);
    // not logged
    if (!token) {
      return this._goToLoginPage(state);
    }

    // todo check date of expiration of token

    return of(true);
  }

  private _goToLoginPage(_: RouterStateSnapshot): Observable<boolean> {
    return of(false).pipe(
      tap(() => {
        this._store.dispatch(new Navigate(['logowanie']));
      }),
    );
  }
}
