import { ChangeDetectorRef, inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

export const SPINNER_NAME = 'CORE_SPINNER_NAME';

@UntilDestroy({ checkProperties: true })
export abstract class BasicPage implements OnInit {
  public loading: boolean;

  protected readonly _ngxSpinnerService: NgxSpinnerService = inject(NgxSpinnerService);
  protected readonly _changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  protected readonly _title: Title = inject(Title);

  protected constructor(protected readonly _pageTitle: string) {
    if (this._title) {
      this._title.setTitle(_pageTitle);
    }
  }

  public ngOnInit(): void {
    this._setLoadingSubscription();
  }

  protected abstract getLoadingObservable$(): Observable<boolean> | undefined;

  private _setLoadingSubscription(): void {
    this.getLoadingObservable$()
      .pipe(untilDestroyed(this))
      .subscribe((loading) => {
        this.loading = !!loading;
        if (loading) {
          this._ngxSpinnerService.show(SPINNER_NAME).then();
        } else {
          this._ngxSpinnerService.hide(SPINNER_NAME).then();
        }
        this._changeDetectorRef.markForCheck();
      });
  }
}
