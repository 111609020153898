import { Component, HostListener, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@Component({
  selector: "amc-root",
  template: `
    <router-outlet></router-outlet>`
})
@UntilDestroy()
export class AppComponent implements OnInit {
  constructor(
    private readonly _ngbModal: NgbModal,
    private readonly _router: Router
  ) {
  }

  @HostListener("window:beforeinstallprompt", ["$event"])
  public onBeforeInstallPrompt(e): void {
    e.preventDefault();
  }

  public async ngOnInit(): Promise<void> {
    this._setRouterSubscription();
  }


  private _setRouterSubscription(): void {
    this._router.events.pipe(untilDestroyed(this)).subscribe((routeEvent) => {
      if (routeEvent instanceof NavigationEnd) {
        this._ngbModal.dismissAll();
      }
    });
  }
}
