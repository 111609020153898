import { Inject, Injectable, Optional } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import {
  ON_CHECK_IF_PRODUCT_IS_VALID,
  OnCheckIfProductIsValid,
  ProductsService,
  SerialNumberService,
} from '../services';
import {
  DocumentTypeEnum,
  IProductDataItem,
  IProductDetails,
  IRegisteredProductDataItem,
  ISerialProductInfo,
} from '../products';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';

export class ProductsStateFetchPromotionProducts {
  public static readonly type = '[ProductsState] fetch promotion products';

  constructor(public readonly payload: { promotionId: number }) {}
}

export class ProductsStateCheckIfSerialValid {
  public static readonly type = '[ProductsState] check if serial valid';

  constructor(public readonly payload: { serial: string }) {}
}

export class ProductsStateFindById {
  public static readonly type = '[ProductsState] find by id';

  constructor(public readonly payload: { amicaApiProductId: number }) {}
}

export class ProductsStateDeleteById {
  public static readonly type = '[ProductsState] delete by id';

  constructor(public readonly payload: { productId: number }) {}
}

export class ProductsStateDownloadDocument {
  public static readonly type = '[ProductsState] download document';

  constructor(
    public readonly payload: {
      document: {
        id: string;
        mime_type: string;
        original_name: string;
        type: { id: DocumentTypeEnum; name: string };
      };
    },
  ) {}
}

export class ProductsStateDownloadFiveYearCertificate {
  public static readonly type = '[ProductsState] download five year certificate';

  constructor(public readonly payload: { product: IRegisteredProductDataItem }) {}
}

export interface ProductsStateModel {
  loading: boolean;
  serialProductInfo: {
    [serial: string]: ISerialProductInfo[];
  };
  amicaProductsDetails: {
    [productId: number]: IProductDetails;
  };
  productsByCategory: {
    [promotionId: number]: {
      [category: string]: IProductDataItem[];
    };
  };
}

@State<ProductsStateModel>({
  name: 'products',
  defaults: {
    loading: false,
    productsByCategory: {},
    serialProductInfo: {},
    amicaProductsDetails: {},
  },
})
@Injectable()
export class ProductsState {
  public constructor(
    private readonly _productsService: ProductsService,
    private readonly _serialNumberService: SerialNumberService,
    private readonly _toastrService: ToastrService,
    @Inject(ON_CHECK_IF_PRODUCT_IS_VALID)
    @Optional()
    private readonly _onCheckIfProductIsValidService?: OnCheckIfProductIsValid,
  ) {}

  @Selector()
  public static loading({ loading }: ProductsStateModel): boolean {
    return loading;
  }

  @Selector()
  public static productsByCategory({ productsByCategory }: ProductsStateModel): {
    [promotionId: number]: { [category: string]: IProductDataItem[] };
  } {
    return productsByCategory;
  }

  @Selector()
  public static serialProductInfo({ serialProductInfo }: ProductsStateModel): {
    [serial: string]: ISerialProductInfo[];
  } {
    return serialProductInfo;
  }

  @Selector()
  public static amicaProductsDetails({ amicaProductsDetails }: ProductsStateModel): {
    [productId: number]: IProductDetails;
  } {
    return amicaProductsDetails;
  }

  @Action(ProductsStateFetchPromotionProducts)
  public fetchProducts(
    { patchState, getState }: StateContext<ProductsStateModel>,
    { payload: { promotionId } }: ProductsStateFetchPromotionProducts,
  ): Observable<{
    [category: string]: IProductDataItem[];
  }> {
    const { productsByCategory } = getState();
    if (productsByCategory[promotionId]) {
      return of(productsByCategory[promotionId]);
    }

    const _groupByCategory = (products: IProductDataItem[]) =>
      products.reduce(
        (acc, value) => {
          // Group initialization
          if (!acc[value?.category?.name]) {
            acc[value?.category?.name] = [];
          }

          // Grouping
          acc[value?.category?.name].push(value);

          return acc;
        },
        {} as {
          [category: string]: IProductDataItem[];
        },
      );

    patchState({ loading: true });
    return this._productsService.fetchProducts(promotionId).pipe(
      map((products) => _groupByCategory(products)),
      tap((products) => {
        const _productsByCategory = { ...productsByCategory };
        _productsByCategory[promotionId] = products;
        patchState({ productsByCategory: _productsByCategory, loading: false });
      }),
      catchError((err) => {
        patchState({ loading: false });
        return throwError(() => err);
      }),
    );
  }

  @Action(ProductsStateCheckIfSerialValid)
  public checkIfSerialValid(
    { patchState, getState }: StateContext<ProductsStateModel>,
    { payload: { serial } }: ProductsStateCheckIfSerialValid,
  ): Observable<ISerialProductInfo[]> {
    const { serialProductInfo } = getState();
    if (serialProductInfo[serial]) {
      patchState({ serialProductInfo: { ...serialProductInfo } });
      return of(serialProductInfo[serial]);
    }
    patchState({ loading: true });
    if (this._onCheckIfProductIsValidService) {
      return this._onCheckIfProductIsValidService.isValid(serial).pipe(
        tap((result) => {
          const _serialProductInfo = { ...serialProductInfo };
          _serialProductInfo[serial] = result;
          patchState({ loading: false, serialProductInfo: _serialProductInfo });
        }),
      );
    }

    return this._serialNumberService.isValid(serial).pipe(
      tap((result) => {
        const _serialProductInfo = { ...serialProductInfo };
        _serialProductInfo[serial] = result;
        patchState({ loading: false, serialProductInfo: _serialProductInfo });
      }),
    );
  }

  @Action(ProductsStateFindById)
  public findById(
    { patchState, getState }: StateContext<ProductsStateModel>,
    { payload: { amicaApiProductId } }: ProductsStateFindById,
  ): Observable<IProductDetails> {
    const { amicaProductsDetails } = getState();
    if (amicaProductsDetails[amicaApiProductId]) {
      patchState({ amicaProductsDetails: { ...amicaProductsDetails } });
      return of(amicaProductsDetails[amicaApiProductId]);
    }
    patchState({ loading: true });
    return this._productsService.findById(amicaApiProductId).pipe(
      tap((result) => {
        const _amicaProductsDetails = { ...amicaProductsDetails };
        _amicaProductsDetails[amicaApiProductId] = result;
        patchState({ loading: false, amicaProductsDetails: _amicaProductsDetails });
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(ProductsStateDeleteById)
  public deleteById(
    { patchState }: StateContext<ProductsStateModel>,
    { payload: { productId } }: ProductsStateDeleteById,
  ): Observable<void> {
    patchState({ loading: true });
    return this._productsService.deleteProductById(productId).pipe(
      catchError((err) => {
        if (err.status == 400) {
          this._toastrService.error(err.error);
        }
        return throwError(err);
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(ProductsStateDownloadDocument)
  public downloadDocument(
    { patchState }: StateContext<ProductsStateModel>,
    { payload: { document } }: ProductsStateDownloadDocument,
  ): Observable<Blob> {
    patchState({ loading: true });
    return this._productsService.downloadDocument(document.id).pipe(
      tap((blob) => {
        saveAs(blob, document.original_name);
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(ProductsStateDownloadFiveYearCertificate)
  public downloadFiveYearCertificate(
    { patchState }: StateContext<ProductsStateModel>,
    { payload: { product } }: ProductsStateDownloadFiveYearCertificate,
  ): Observable<Blob> {
    patchState({ loading: true });
    return this._productsService.downloadFiveYearCertificate(product.id).pipe(
      tap((blob) => {
        saveAs(
          blob,
          `Certyfikat_Amica_${product.product.name?.replace(/\s+/g, '_')}_${product.product.symbol?.replace(
            /\s+/g,
            '_',
          )}.pdf`,
        );
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }
}
