import { Inject, Injectable } from '@angular/core';
import { SERVICES_API, SERVICES_BEARER_TOKEN } from './tokens';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISurveyAnswerDto, ISurveyDetails, QuestionTypeEnum } from '../survey';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  constructor(
    @Inject(SERVICES_API)
    private _servicesApi: string,
    @Inject(SERVICES_BEARER_TOKEN)
    private _servicesBearerToken: string,
    private readonly _httpClient: HttpClient,
  ) {}

  /**
   * Get poll details
   * @param surveyId
   */
  public findById(surveyId: number): Observable<ISurveyDetails> {
    return this._httpClient
      .get<ISurveyDetails>(`${this._servicesApi}poll/${surveyId}`, {
        headers: { Authorization: `Bearer ${this._servicesBearerToken}` },
      })
      .pipe(
        map((value) => ({
          ...value,
          questions: value.questions.map((question) => {
            if (question.type === QuestionTypeEnum.MULTIPLE_CHOICE && question.data.question.indexOf('max. 2') !== -1) {
              return { ...question, max: 2 };
            }
            return question;
          }),
        })),
      );
  }

  /**
   * Add survey answers for given survey ID
   * @param surveyId
   * @param surveyAnswerDto
   */
  public answer(surveyId: number, surveyAnswerDto: ISurveyAnswerDto): Observable<void> {
    return this._httpClient.post<void>(`${this._servicesApi}poll/${surveyId}/answer`, surveyAnswerDto, {
      headers: { Authorization: `Bearer ${this._servicesBearerToken}` },
    });
  }
}
