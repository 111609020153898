import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ILoginDto, ILoginResponse } from '@am-canteens/data';
import { SERVICES_API, SERVICES_BEARER_TOKEN } from './tokens';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    @Inject(SERVICES_API)
    private readonly _servicesApi: string,
    @Inject(SERVICES_BEARER_TOKEN)
    private readonly _servicesBearerToken: string,

    private readonly _httpClient: HttpClient,
  ) {}

  /**
   * Login customer by username and password. Returns token and token expiration date.
   * @param loginDto
   */
  public login(loginDto: ILoginDto): Observable<ILoginResponse> {
    return this._httpClient.post<ILoginResponse>(`${this._servicesApi}my-amica/login`, loginDto, {
      headers: { Authorization: `Bearer ${this._servicesBearerToken}` },
    });
  }
}
