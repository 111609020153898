import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUser, IUserUpdateDto } from '../user';
import { SERVICES_API, SERVICES_BEARER_TOKEN, SERVICES_CUSTOMER_TOKEN } from './tokens';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    @Inject(SERVICES_API)
    private _servicesApi: string,
    @Inject(SERVICES_BEARER_TOKEN)
    private _servicesBearerToken: string,
    @Inject(SERVICES_CUSTOMER_TOKEN)
    private _servicesCustomerToken: () => string,
    private readonly _httpClient: HttpClient,
  ) {}

  /**
   * Get customer account details
   * @param token
   */
  public getMe(token: string): Observable<IUser> {
    return this._httpClient.get<IUser>(`${this._servicesApi}my-amica/customer`, {
      headers: {
        Authorization: `Bearer ${this._servicesBearerToken}`,
        CustomerAuthorization: `Token ${token}`,
      },
    });
  }

  /**
   * Update customer account details
   * @param userEditDto
   */
  public updateAddress(userEditDto: IUserUpdateDto): Observable<void> {
    return this._httpClient.post<void>(`${this._servicesApi}my-amica/customer/update-address`, userEditDto, {
      headers: {
        Authorization: `Bearer ${this._servicesBearerToken}`,
        CustomerAuthorization: `Token ${this._servicesCustomerToken()}`,
      },
    });
  }

  /**
   * Validate customer mobile number and send SMS with code
   * @param mobile
   * @param mobile_prefix
   */
  public validateMobile(mobile: string, mobile_prefix: string): Observable<void> {
    return this._httpClient.post<void>(
      `${this._servicesApi}my-amica/customer/validate-mobile`,
      {
        mobile: mobile.replace(`${mobile_prefix}`, ''),
        mobile_prefix: mobile_prefix.replace('+', ''),
      },
      {
        headers: {
          Authorization: `Bearer ${this._servicesBearerToken}`,
          CustomerAuthorization: `Token ${this._servicesCustomerToken()}`,
        },
      },
    );
  }

  /**
   * Validate customer SMS code
   * @param code
   */
  public validateMobileSmsCode(code: string): Observable<void> {
    return this._httpClient.post<void>(
      `${this._servicesApi}my-amica/customer/validate-mobile-sms-code`,
      { code },
      {
        headers: {
          Authorization: `Bearer ${this._servicesBearerToken}`,
          CustomerAuthorization: `Token ${this._servicesCustomerToken()}`,
        },
      },
    );
  }
}
