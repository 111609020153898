import {
  DOCUMENT,
  FORM_CSS_CLASS,
  GOOGLE_MAPS_KEY,
  LoginState,
  SERVICES_API,
  SERVICES_BEARER_TOKEN,
  SERVICES_CONFIRMATION_LINK,
  SERVICES_CUSTOMER_TOKEN,
  SERVICES_UPLOAD_FILE_LOCATION,
  SYNERISE_KEY,
  UserService,
  UserState,
  UserStateLogout,
  UserStateSetUserData,
  WINDOW,
} from '@am-canteens/data';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { ToastrModule } from 'ngx-toastr';
import { EMPTY, firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { ErrorHandlerService } from './services/error-handler.service';
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import { AuthGuardService } from './services/auth-guard.service';
import { SchoolState } from './core/_states/school.state';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { provideNgxMask } from 'ngx-mask';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GoogleTagManagerModule } from "angular-google-tag-manager";

const LOCAL_STORAGE_STATE_VALUES = ['user.token', 'user.tokenExpiredAt'];

registerLocaleData(localePl);

const NGXS_MODULES = [
  NgxsModule.forRoot([UserState, SchoolState, LoginState], {
    developmentMode: !environment.production,
  }),
  NgxsRouterPluginModule.forRoot(),
  NgxsFormPluginModule.forRoot(),
  NgxsLoggerPluginModule.forRoot({
    logger: console,
    collapsed: false,
    disabled: environment.production,
  }),
  NgxsStoragePluginModule.forRoot({
    storage: StorageOption.LocalStorage,
    key: [...LOCAL_STORAGE_STATE_VALUES],
  }),
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // for NgxIntlTelInputModule
    BsDropdownModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
    }),
    AppRoutingModule,
    GoogleTagManagerModule.forRoot({
      id: environment.gtm,
    }),
    ...NGXS_MODULES,
  ],
  providers: [
    AuthGuardService,
    provideNgxMask(),
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: WINDOW, useValue: window },
    { provide: DOCUMENT, useValue: document },
    { provide: SERVICES_API, useValue: environment.api },
    { provide: SERVICES_BEARER_TOKEN, useValue: environment.bearerToken },
    { provide: SERVICES_CONFIRMATION_LINK, useValue: environment.confirmationLink },
    { provide: GOOGLE_MAPS_KEY, useValue: environment.googleMapsKey },
    { provide: SERVICES_UPLOAD_FILE_LOCATION, useValue: environment.uploadFilesLocation },
    { provide: FORM_CSS_CLASS, useValue: 'amc-my-form' },
    { provide: SYNERISE_KEY, useValue: null },
    {
      provide: SERVICES_CUSTOMER_TOKEN,
      useFactory: (store: Store) => () => store.selectSnapshot(UserState.token),
      deps: [Store],
    },
    { provide: LOCALE_ID, useValue: 'pl-PL' },
    {
      provide: APP_INITIALIZER,
      useFactory: (userService: UserService, store: Store) => async () => {
        const token = store.selectSnapshot(UserState.token);
        const jwtHelperService = new JwtHelperService();
        if (jwtHelperService.isTokenExpired(token) || !token) {
          return EMPTY;
        }
        return await firstValueFrom(userService.getMe(token))
          .then((user) => {
            store.dispatch(new UserStateSetUserData({ user }));
          })
          .catch((reason) => {
            if (reason?.status === 403) {
              setTimeout(() => {
                store.dispatch(new UserStateLogout());
              }, 200);
            }
          });
      },
      deps: [UserService, Store],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
