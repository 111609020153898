import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IListResponseData, ISchoolDataItem, ISchoolDto, UtilsService } from '@am-canteens/data';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SchoolService {
  constructor(private readonly _httpClient: HttpClient) {}

  /**
   * get list of schools
   * @param schoolDto
   */
  public getSchools(schoolDto: ISchoolDto): Observable<IListResponseData<ISchoolDataItem>> {
    const modifiedListDto = UtilsService.deleteUnnecessaryProperties<ISchoolDto>(schoolDto);
    return this._httpClient.get<IListResponseData<ISchoolDataItem>>(`${environment.api}amica-school/list`, {
      headers: {
        Authorization: `Bearer ${environment.bearerToken}`,
      },
      params: JSON.parse(JSON.stringify(modifiedListDto)),
    });
  }

  /**
   * Apply all amica school agreements
   * @param token
   */
  public applyAgreement(token: string): Observable<void> {
    return this._httpClient.post<void>(`${environment.api}amica-school/apply-agreement`, null, {
      headers: {
        Authorization: `Bearer ${environment.bearerToken}`,
        CustomerAuthorization: `Token ${token}`,
      },
    });
  }
}
