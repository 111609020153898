import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OnCheckIfProductIsValid, SERVICES_API, SERVICES_BEARER_TOKEN } from './tokens';
import { ISerialProductInfo } from '../products';

@Injectable({
  providedIn: 'root',
})
export class SerialNumberService implements OnCheckIfProductIsValid {
  constructor(
    @Inject(SERVICES_API)
    private _servicesApi: string,
    @Inject(SERVICES_BEARER_TOKEN)
    private _servicesBearerToken: string,
    private readonly _httpClient: HttpClient,
  ) {}

  /**
   * Check if serial number exists in the system
   * @param serial
   */
  public exists(serial: string): Observable<unknown> {
    return this._httpClient.post<unknown>(`${this._servicesApi}serial-number/exists`, null, {
      headers: { Authorization: `Bearer ${this._servicesBearerToken}` },
      params: {
        serial,
      },
    });
  }

  /**
   * Check if serial number is valid and return amica product assigned with this serial number
   * @param serial
   */
  public isValid(serial: string): Observable<ISerialProductInfo[]> {
    return this._httpClient.post<ISerialProductInfo[]>(`${this._servicesApi}serial-number/is-valid`, null, {
      headers: { Authorization: `Bearer ${this._servicesBearerToken}` },
      params: {
        serial,
      },
    });
  }
}
