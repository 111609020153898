import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT, GOOGLE_MAPS_KEY } from './tokens';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapService {
  public readonly ID_GM_SCRIPT = 'GMscript';

  private _isLoaded = false;

  private readonly _renderer2: Renderer2;

  constructor(
    @Inject(GOOGLE_MAPS_KEY)
    private readonly _googleMapKey: string,
    @Inject(DOCUMENT)
    private readonly _document: Document,
    private readonly _rendererFactory: RendererFactory2,
  ) {
    this._renderer2 = _rendererFactory.createRenderer(null, null);
  }

  public addGoogleMapToDom(): Promise<unknown> {
    return new Promise((resolve, reject) => {
      if (this._document.getElementById(this.ID_GM_SCRIPT)) {
        this._isLoaded = true;
        resolve(true);
        return;
      } else if (this._isLoaded) {
        resolve(true);
        return;
      }
      const gmScript = this._document.createElement('script');
      gmScript.type = 'text/javascript';
      gmScript.id = this.ID_GM_SCRIPT;
      gmScript.defer = true;
      gmScript.async = true;
      gmScript.onload = resolve;
      gmScript.onerror = reject;
      gmScript.src = `https://maps.googleapis.com/maps/api/js?key=${this._googleMapKey}&libraries=places&language=pl`;
      this._document.body.appendChild(gmScript);
      this._isLoaded = true;
    });
  }
}
