import { ChangeDetectorRef, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NgxSpinnerService } from 'ngx-spinner';
import { BasicPage } from './basic-page';
import { Select, Store } from '@ngxs/store';
import {
  ECodesState,
  ECodesStateFetchCities,
  ECodesStateFetchCityECodes,
  ECodesStateFetchOnlineECodes,
  ECodesStateFetchStates,
  ProductsState,
  ProductsStateCheckIfSerialValid,
  PromotionsStateAddProduct,
} from '../states';
import { Observable } from 'rxjs';
import { ECodeTypeEnum, IECodeDataItem, IState } from '../e-codes';
import { IFileInputResult, IProductDataForDisplay, ISerialProductInfo } from '../products';
import { FileUploaderOptions } from 'ng2-file-upload';
import { GoogleMapService } from '../services';
import { UntypedFormGroup } from '@angular/forms';
import { DropdownPosition } from '@ng-select/ng-select';

@UntilDestroy()
export abstract class ProductFormPage extends BasicPage implements OnInit {
  @Select(ECodesState.states)
  public states$: Observable<IState[]>;
  @Select(ECodesState.onlineECodes)
  public onlineECodes$: Observable<IECodeDataItem[]>;
  @Select(ECodesState.cities)
  public cities$: Observable<{ [stateId: number]: IState[] }>;
  @Select(ECodesState.cityECodes)
  public cityECodes$: Observable<{ [city: string]: IECodeDataItem[] }>;
  @Select(ECodesState.loading)
  public eCodesStateLoading$: Observable<boolean>;

  @Select(ProductsState.loading)
  public productsStateLoading$: Observable<boolean>;
  @Select(ProductsState.serialProductInfo)
  public serialProductInfo$: Observable<{ [serial: string]: ISerialProductInfo[] }>;

  public readonly fileUploaderOptions: FileUploaderOptions = {
    url: null,
    autoUpload: false,
    queueLimit: 1,
    maxFileSize: 2097152, // 2mb
    allowedFileType: ['image', 'pdf'],
    allowedMimeType: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
  };

  public readonly selectShopTypeOptions: { value: ECodeTypeEnum; text: string }[] = [
    { value: ECodeTypeEnum.WWW, text: 'Sklep internetowy' },
    { value: ECodeTypeEnum.STATIONARY, text: 'Sklep stacjonarny' },
  ];

  public readonly dropdownPosition: DropdownPosition = 'bottom';

  protected constructor(
    protected readonly _ngxSpinnerService: NgxSpinnerService,
    protected readonly _title: Title,
    protected readonly _pageTitle: string,
    protected readonly _store: Store,
    protected readonly _googleMapService: GoogleMapService,
    protected readonly _changeDetectorRef: ChangeDetectorRef,
  ) {
    super(_pageTitle);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._googleMapService.addGoogleMapToDom().then();
    this._store.dispatch(new ECodesStateFetchOnlineECodes());
    this._store.dispatch(new ECodesStateFetchStates());
  }

  public onSerialNumberChange(serial: string): void {
    const serialNumberRequiredLengths = [14, 15];
    if (!serial || !serialNumberRequiredLengths.includes(serial.length)) {
      return;
    }
    this._store.dispatch(new ProductsStateCheckIfSerialValid({ serial }));
  }

  public onShopStationaryRegionChange(stateId: number): void {
    if (!stateId) {
      return;
    }
    this._store.dispatch(new ECodesStateFetchCities({ stateId }));
  }

  public onShopStationaryCityChange(city: string): void {
    if (!city) {
      return;
    }
    this._store.dispatch(new ECodesStateFetchCityECodes({ city }));
  }

  public onSubmitProduct($event: {
    invoiceFile: IFileInputResult;
    warrantyCardFile: IFileInputResult;
    otherDocumentFile: IFileInputResult;
    productDataForDisplay: IProductDataForDisplay;
    formGroup: UntypedFormGroup;
  }): void {
    this._store.dispatch(new PromotionsStateAddProduct($event));
  }
}
