import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import {
  IListResponseData,
  INgxsFormModel,
  ISchoolCreateDto,
  ISchoolDataItem,
  ISchoolDto,
  IUser,
  SchoolCategory,
  SchoolStatus,
  UserService,
  UserState,
  UserStateSetUserData,
} from '@am-canteens/data';
import { concatMap, finalize, tap } from 'rxjs/operators';
import { SchoolService } from '../../services/school.service';

export class SchoolStateFetchTopSchools {
  public static readonly type = '[SchoolState] fetch top schools';
}

export class SchoolStateSearchSchools {
  public static readonly type = '[SchoolState] search schools';

  constructor(
    public readonly payload: {
      page?: number;
      school_status?: SchoolStatus;
      school_category?: SchoolCategory;
      city?: string;
      zip_code?: string;
      name?: string;
    },
  ) {}
}

export class SchoolStateClearSchools {
  public static readonly type = '[SchoolState] clear schools';
}

export class SchoolStateApplyAllSchoolAgreements {
  public static readonly type = '[SchoolState] apply all school agreements';
}

export interface SchoolStateModel {
  loading: boolean;
  searchLoading: boolean;
  schoolDto: ISchoolDto;
  topSchools: ISchoolDataItem[];
  schoolsByCategory: {
    [category: string]: ISchoolDataItem[];
  };
  schoolsByStatus: {
    [status: string]: ISchoolDataItem[];
  };
  schools: IListResponseData<ISchoolDataItem>;
  form: INgxsFormModel<ISchoolCreateDto>;
}

@State<SchoolStateModel>({
  name: 'schools',
  defaults: {
    loading: false,
    searchLoading: false,
    schoolDto: null,
    topSchools: [],
    schoolsByCategory: {},
    schoolsByStatus: {},
    schools: null,
    form: null,
  },
})
@Injectable()
export class SchoolState {
  constructor(
    private readonly _schoolService: SchoolService,
    private readonly _store: Store,
    private readonly _userService: UserService,
  ) {}

  @Selector()
  public static loading({ loading }: SchoolStateModel): boolean {
    return loading;
  }

  @Selector()
  public static searchLoading({ searchLoading }: SchoolStateModel): boolean {
    return searchLoading;
  }

  @Selector()
  public static schools({ schools }: SchoolStateModel): IListResponseData<ISchoolDataItem> {
    return schools;
  }

  @Selector()
  public static topSchools({ topSchools }: SchoolStateModel): ISchoolDataItem[] {
    return topSchools;
  }

  @Selector()
  public static schoolsByCategory({
    schoolsByCategory,
  }: SchoolStateModel): {
    [category: string]: ISchoolDataItem[];
  } {
    return schoolsByCategory;
  }

  @Selector()
  public static schoolsByStatus({
    schoolsByStatus,
  }: SchoolStateModel): {
    [status: string]: ISchoolDataItem[];
  } {
    return schoolsByStatus;
  }

  @Action(SchoolStateFetchTopSchools)
  public fetchTopSchools({
    patchState,
  }: StateContext<SchoolStateModel>): Observable<IListResponseData<ISchoolDataItem>> {
    patchState({ loading: true });
    return this._schoolService
      .getSchools({
        page: 1,
        sort: 'votes',
        perPage: 5,
        direction: 'desc',
        school_status: SchoolStatus.VERIFIED,
      })
      .pipe(
        tap((listResponseData) => {
          patchState({ loading: false, topSchools: listResponseData.data });
        }),
      );
  }

  @Action(SchoolStateSearchSchools)
  public searchSchools(
    { patchState }: StateContext<SchoolStateModel>,
    { payload: { page, school_status, school_category, city, name, zip_code } }: SchoolStateSearchSchools,
  ): Observable<IListResponseData<ISchoolDataItem>> {
    const _groupBy = (schools: ISchoolDataItem[], key: string) =>
      schools.reduce(
        (acc, value) => {
          if (!acc[value[key]]) {
            acc[value[key]] = [];
          }
          acc[value[key]].push(value);
          return acc;
        },
        {} as {
          [key: string]: ISchoolDataItem[];
        },
      );

    const schoolDto: ISchoolDto = {
      page: page || 1,
      sort: 'votes',
      perPage: 20,
      direction: 'desc',
      school_status: school_status || null,
      category: school_category || null,
      city,
      name,
      zip_code,
    };
    patchState({ searchLoading: true, schoolDto });
    return this._schoolService.getSchools(schoolDto).pipe(
      tap((listResponseData) => {
        const schoolsByCategory = _groupBy(listResponseData.data, 'category');
        const schoolsByStatus = _groupBy(listResponseData.data, 'school_status');
        patchState({ searchLoading: false, schoolsByCategory, schoolsByStatus, schools: listResponseData });
      }),
    );
  }

  @Action(SchoolStateClearSchools)
  public clearSchools({ patchState }: StateContext<SchoolStateModel>): void {
    patchState({ schools: null });
  }

  @Action(SchoolStateApplyAllSchoolAgreements)
  public applyAllSchoolAgreements({ patchState, dispatch }: StateContext<SchoolStateModel>): Observable<IUser> {
    const token = this._store.selectSnapshot(UserState.token);
    if (!token) {
      return of(null);
    }
    patchState({ loading: true });
    return this._schoolService
      .applyAgreement(token)
      .pipe(concatMap(() => this._userService.getMe(token)))
      .pipe(
        tap((user) => {
          dispatch(new UserStateSetUserData({ user }));
        }),
        finalize(() => {
          patchState({ loading: false });
        }),
      );
  }
}
