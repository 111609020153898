import { InjectionToken } from '@angular/core';
import { getDocument, getWindow } from 'ssr-window';
import { Observable } from 'rxjs';
import { ISerialProductInfo } from '../products';

export const FACEBOOK_PIXEL_ID = new InjectionToken<string>('facebookPixelId');
export const WINDOW = new InjectionToken<Window>('WINDOW', {
  factory: () => getWindow(),
});
export const DOCUMENT = new InjectionToken<Document>('DOCUMENT', {
  factory: () => getDocument(),
});
export const SERVICES_API = new InjectionToken<string>('SERVICES_API');
export const SERVICES_OLD_API = new InjectionToken<string>('SERVICES_OLD_API');
export const SERVICES_BEARER_TOKEN = new InjectionToken<string>('SERVICES_BEARER_TOKEN');
export const SERVICES_CUSTOMER_TOKEN = new InjectionToken<() => string>('SERVICES_CUSTOMER_TOKEN');
export const SERVICES_UPLOAD_FILE_LOCATION = new InjectionToken<() => string>('SERVICES_UPLOAD_FILE_LOCATION');
export const SERVICES_CONFIRMATION_LINK = new InjectionToken<() => string>('SERVICES_CONFIRMATION_LINK');
export const GOOGLE_MAPS_KEY = new InjectionToken<string>('GOOGLE_MAPS_KEY');
export const SYNERISE_KEY = new InjectionToken<string>('SYNERISE_KEY');
export const FORM_CSS_CLASS = new InjectionToken<string>('FORM_CSS_CLASS');

export interface OnCheckIfProductIsValid {
  isValid(serial: string): Observable<ISerialProductInfo[]>;
}
export const ON_CHECK_IF_PRODUCT_IS_VALID = new InjectionToken<OnCheckIfProductIsValid>('ON_CHECK_IF_PRODUCT_IS_VALID');
