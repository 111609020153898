import { IProductDataItem, IRegisteredProductDataItem } from '../products/index';
import { SERVICES_API, SERVICES_BEARER_TOKEN, SERVICES_CUSTOMER_TOKEN } from './tokens';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IProductDetails } from '../products/product-details';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(
    @Inject(SERVICES_API)
    private _servicesApi: string,
    @Inject(SERVICES_BEARER_TOKEN)
    private _servicesBearerToken: string,
    @Inject(SERVICES_CUSTOMER_TOKEN)
    private _servicesCustomerToken: () => string,
    private readonly _httpClient: HttpClient,
  ) {}

  /**
   * Returns only Amica products available for promotion. List is ordered by product name.
   * @param promotionId
   * @param limit - Query limit
   * @param search - Search products by any text (name,code,symbol,ean)
   */
  public fetchProducts(promotionId: number, limit = 9999, search?: string): Observable<IProductDataItem[]> {
    const params = {
      limit: String(limit),
    };
    if (search) {
      params['search'] = search;
    }
    return this._httpClient.get<IProductDataItem[]>(`${this._servicesApi}amica-products/promotion/${promotionId}`, {
      headers: { Authorization: `Bearer ${this._servicesBearerToken}` },
      params,
    });
  }

  /**
   * Get customer registered products
   */
  public fetchRegisteredProducts(): Observable<IRegisteredProductDataItem[]> {
    return this._httpClient.get<IRegisteredProductDataItem[]>(`${this._servicesApi}my-amica/customer/product`, {
      headers: {
        Authorization: `Bearer ${this._servicesBearerToken}`,
        CustomerAuthorization: `Token ${this._servicesCustomerToken()}`,
      },
    });
    // for testing purposes
    // .pipe(
    //   map((products) =>
    //     products
    //       .map((product, index) => {
    //         if (index === 0) {
    //           return { ...product, product_serial_number: null };
    //         }
    //         return product;
    //       })
    //       .filter((product) => product.product_serial_number === null),
    //   ),
    // );
  }

  /**
   * Get Amica product details by Amica product ID;
   * @param productId - Amica Product ID
   */
  public findById(productId: number): Observable<IProductDetails> {
    return this._httpClient.get<IProductDetails>(`${this._servicesApi}product/${productId}/details`, {
      headers: { Authorization: `Bearer ${this._servicesBearerToken}` },
    });
  }

  /**
   * Get document content
   * @param id
   */
  public downloadDocument(id: string): Observable<Blob> {
    return this._httpClient.get<Blob>(`${this._servicesApi}document/download/${id}`, {
      headers: {
        Authorization: `Bearer ${this._servicesBearerToken}`,
        CustomerAuthorization: `Token ${this._servicesCustomerToken()}`,
      },
      responseType: 'blob' as 'json',
    });
  }

  /**
   * Get application product certificate (5 lat gwarancji)
   * @param id
   */
  public downloadFiveYearCertificate(id: number): Observable<Blob> {
    return this._httpClient.get<Blob>(`${this._servicesApi}my-amica/customer/product/${id}/certificate-5-years`, {
      headers: {
        Authorization: `Bearer ${this._servicesBearerToken}`,
        CustomerAuthorization: `Token ${this._servicesCustomerToken()}`,
      },
      responseType: 'blob' as 'json',
    });
  }

  /**
   * Remove customer product
   * @param productId - Registered product ID
   */
  public deleteProductById(productId: number): Observable<void> {
    return this._httpClient.delete<void>(`${this._servicesApi}my-amica/customer/product/${productId}/remove`, {
      headers: {
        Authorization: `Bearer ${this._servicesBearerToken}`,
        CustomerAuthorization: `Token ${this._servicesCustomerToken()}`,
      },
    });
  }
}
