import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Utils } from '@am-canteens/utils';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(private readonly _toastrService: ToastrService) {}

  public static clearFormControl(formControl: AbstractControl): void {
    formControl.setValue(null);
    formControl.updateValueAndValidity();
  }

  public static scrollToElementId(elementToScrollId: string): boolean {
    const element = document.getElementById(elementToScrollId);
    const headerOffset = 100;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition - headerOffset + window.pageYOffset;
    window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    return false;
  }

  public static deleteUnnecessaryProperties<S>(listDto: S): S {
    const _listDto = { ...listDto };
    Object.keys(_listDto).forEach(
      (key) => (_listDto[key] === undefined || _listDto[key] === null) && delete _listDto[key],
    );
    return _listDto;
  }

  public displayErrorMessageIfExists(error: any): void {
    if (
      error?.error?.errors?.errors &&
      Array.isArray(error?.error?.errors?.errors) &&
      error?.error?.errors?.errors.length
    ) {
      this._toastrService.error(error?.error?.errors?.errors[0]);
    }
  }

  public catchFormError(error: any, formGroup: UntypedFormGroup): Observable<any> {
    this.displayErrorMessageIfExists(error);
    if (error?.error?.code === 400) {
      Utils.setErrorsAndScrollToThem(error?.error?.errors?.children, formGroup);
      formGroup.updateValueAndValidity();
      return of(null);
    }
    return throwError(error);
  }
}
