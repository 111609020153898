import { Inject, Injectable } from '@angular/core';
import { SERVICES_API, SERVICES_BEARER_TOKEN } from './tokens';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICity, IECodeDataItem, IECodesDto, IState } from '../e-codes';
import { IListResponseData } from '../abstract';

@Injectable({
  providedIn: 'root',
})
export class ECodesService {
  constructor(
    @Inject(SERVICES_API)
    private _servicesApi: string,
    @Inject(SERVICES_BEARER_TOKEN)
    private _servicesBearerToken: string,
    private readonly _httpClient: HttpClient,
  ) {}

  /**
   * Get list of states
   */
  public getStates(): Observable<IState[]> {
    return this._httpClient.get<IState[]>(`${this._servicesApi}states`, {
      headers: {
        Authorization: `Bearer ${this._servicesBearerToken}`,
      },
    });
  }

  /**
   * Get list of unique ecode cities
   * @param stateId - Filter ecodes by state ID
   */
  public getCities(stateId: number): Observable<ICity[]> {
    return this._httpClient.get<ICity[]>(`${this._servicesApi}ecodes/cities`, {
      headers: {
        Authorization: `Bearer ${this._servicesBearerToken}`,
      },
      params: {
        state_id: `${stateId}`,
      },
    });
  }

  /**
   * Get list of eCode networks
   */
  public getECodesNetworks(): Observable<{ id: number; name: string }[]> {
    return this._httpClient.get<{ id: number; name: string }[]>(`${this._servicesApi}ecode-networks`);
  }

  /**
   * Get list of eCodes.
   */
  public getECodes(eCodesDto: IECodesDto): Observable<IListResponseData<IECodeDataItem>> {
    return this._httpClient.get<IListResponseData<IECodeDataItem>>(`${this._servicesApi}ecodes`, {
      params: JSON.parse(JSON.stringify(eCodesDto)),
      headers: {
        Authorization: `Bearer ${this._servicesBearerToken}`,
      },
    });
  }
}
