import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ISurveyAnswerDto, ISurveyDetails } from '../survey';
import { SurveyService } from '../services';
import { Observable, of } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

export class SurveyStateFindById {
  public static readonly type = '[SurveyState] find by id';

  constructor(
    public readonly payload: {
      surveyId: number;
    },
  ) {}
}

export class SurveyStateAnswer {
  public static readonly type = '[SurveyState] answer';

  constructor(
    public readonly payload: {
      surveyId: number;
      surveyAnswerDto: ISurveyAnswerDto;
    },
  ) {}
}

export interface SurveyStateModel {
  loading: boolean;
  surveyDetails: { [surveyId: number]: ISurveyDetails };
}

@State<SurveyStateModel>({
  name: 'survey',
  defaults: {
    loading: false,
    surveyDetails: {},
  },
})
@Injectable()
export class SurveyState {
  constructor(private readonly _surveyService: SurveyService) {}

  @Selector()
  public static loading({ loading }: SurveyStateModel): boolean {
    return loading;
  }

  @Selector()
  public static surveyDetails({ surveyDetails }: SurveyStateModel): { [surveyId: number]: ISurveyDetails } {
    return surveyDetails;
  }

  @Action(SurveyStateFindById)
  public findById(
    { patchState, getState }: StateContext<SurveyStateModel>,
    { payload: { surveyId } }: SurveyStateFindById,
  ): Observable<ISurveyDetails> {
    const { surveyDetails } = getState();
    if (surveyDetails[surveyId]) {
      return of(surveyDetails[surveyId]);
    }

    patchState({ loading: true });
    return this._surveyService.findById(surveyId).pipe(
      tap((_sDetails) => {
        const _surveyDetails = { ...surveyDetails };
        _surveyDetails[surveyId] = _sDetails;
        patchState({ loading: false, surveyDetails: _surveyDetails });
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(SurveyStateAnswer)
  public answer(
    { patchState }: StateContext<SurveyStateModel>,
    { payload: { surveyId, surveyAnswerDto } }: SurveyStateAnswer,
  ): Observable<void> {
    patchState({ loading: true });
    return this._surveyService.answer(surveyId, surveyAnswerDto).pipe(
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }
}
