import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  IAddApplicationDto,
  ICampaignColorSubmitDto,
  IPromotionDataItem,
  IPromotionDetails,
  IPromotionHistoryDataItem,
  IPromotionsDto,
  ISendWarrantyDto,
  PromotionStatusEnum,
} from '../promotion';
import {
  DocumentTypeEnum,
  IAddedProduct,
  IFileInputResult,
  IInvoice,
  IOtherDocument,
  IProductDataForDisplay,
  IProductFormModel,
  IRegisteredProductDataItem,
  IRegisterProductDto,
  IUserSummaryFormModel,
  IWarrantyCard,
} from '../products';
import { ECodeTypeEnum, IECodeDataItem } from '../e-codes';
import { IMobileInfo, IUser } from '../user';
import { PromotionService, SyneriseService } from '../services';
import { IListResponseData, INgxsFormModel } from '../abstract';
import { concatMap, Observable, of, throwError, timeout } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Utils } from '@am-canteens/utils';
import { UntypedFormGroup } from '@angular/forms';
import { ISynPromotionApplicationDto, ISynRegisterDto, ISynRegisterProductDto, SyneriseActionEnum } from '../synerise';
import { ECodesState } from './e-codes.state';
import { ReCaptchaV3Service } from 'ng-recaptcha';

export class PromotionsStateFetchActivePromotions {
  public static readonly type = '[PromotionsState] fetch active promotions';
}

export class PromotionsStateFetchDedicatedPromotions {
  public static readonly type = '[PromotionsState] fetch dedicated promotions';
}

export class PromotionsStateFetchUserHistoryPromotions {
  public static readonly type = '[PromotionsState] fetch user promotions';
}

export class PromotionsStateAddProduct {
  public static readonly type = '[PromotionsState] add product';

  constructor(
    public readonly payload: {
      invoiceFile: IFileInputResult;
      warrantyCardFile: IFileInputResult;
      otherDocumentFile: IFileInputResult | null;
      productDataForDisplay: IProductDataForDisplay;
    },
  ) {}
}

export class PromotionsStateAddRegisteredProduct {
  public static readonly type = '[PromotionsState] add registered product';

  constructor(
    public readonly payload: {
      registeredProductDataItem: IRegisteredProductDataItem;
    },
  ) {}
}

export class PromotionsStateRegisterProduct {
  public static readonly type = '[PromotionsState] register product';

  constructor(
    public readonly payload: {
      invoiceFile: IFileInputResult;
      warrantyCardFile: IFileInputResult;
      formGroup: UntypedFormGroup;
      productDataForDisplay: IProductDataForDisplay;
    },
  ) {}
}

export class PromotionsStateDeleteProduct {
  public static readonly type = '[PromotionsState] delete product';

  constructor(
    public readonly payload: {
      index: number;
    },
  ) {}
}

export class PromotionsStateDeleteRegisteredProduct {
  public static readonly type = '[PromotionsState] delete registered product';

  constructor(
    public readonly payload: {
      index: number;
    },
  ) {}
}

export class PromotionsStateAddApplication {
  public static readonly type = '[PromotionsState] add application';

  constructor(
    public readonly payload: {
      promotionId: number;
      user?: IUser;
      description: string | null;
      agreements: {
        newsletterAgreement?: boolean;
        marketing_agreement: boolean;
        service_agreement: boolean;
      };
    },
  ) {}
}

export class PromotionsStateSendWarranty {
  public static readonly type = '[PromotionsState] send warranty';

  constructor(
    public readonly payload: {
      promotionId: number;
      user?: IUser;
      description: string | null;
      agreements: {
        agreementOne: boolean;
        agreementOneText: string;
        agreementTwo: boolean;
        agreementTwoText: string;
        newsletterAgreement: boolean;
      };
      questions: {
        question1: string;
        question2: string;
        question3: string;
        question4: string;
        question5: string;
      };
    },
  ) {}
}

// TODO - remove this action when the competition color submit is no longer needed
export class PromotionsStateCompetitionColorSubmit {
  public static readonly type = '[PromotionsState] competition color submit';

  constructor(
    public readonly payload: {
      promotionId: number;
      user?: IUser;
      agreement: boolean;
      comment: string;
      screen: IFileInputResult;
      mesh: IFileInputResult;
      template: IFileInputResult;
    },
  ) {}
}

// TODO - remove this action when the competition color submit is no longer needed
export class PromotionsStateCompetitionColorVote {
  public static readonly type = '[PromotionsState] competition color vote';

  constructor(
    public readonly payload: {
      voteImage: string;
    },
  ) {}
}

export class PromotionsStateFindById {
  public static readonly type = '[PromotionsState] find by id';

  constructor(
    public readonly payload: {
      promotionId: number;
    },
  ) {}
}

export interface PromotionsStateModel {
  createdApplicationId: string;
  registeredProductId: string;
  loading: boolean;
  activePromotions: IListResponseData<IPromotionDataItem>;
  dedicatedPromotions: IListResponseData<IPromotionDataItem>;
  userPromotionsHistory: IPromotionHistoryDataItem[];
  productForm: INgxsFormModel<IProductFormModel>;
  summaryUserForm: INgxsFormModel<IUserSummaryFormModel>;
  addedProducts: Array<IAddedProduct>;
  addedRegisteredProducts: Array<IRegisteredProductDataItem>;
  promotionsDetails: {
    [promotionId: number]: IPromotionDetails;
  };
}

@State<PromotionsStateModel>({
  name: 'promotions',
  defaults: {
    createdApplicationId: null,
    registeredProductId: null,
    loading: false,
    activePromotions: null,
    dedicatedPromotions: null,
    userPromotionsHistory: null,
    productForm: {
      model: null,
      status: null,
      errors: null,
      disabled: false,
      dirty: false,
    },
    summaryUserForm: {
      model: null,
      status: null,
      errors: null,
      disabled: false,
      dirty: false,
    },
    addedProducts: [],
    addedRegisteredProducts: [],
    promotionsDetails: {},
  },
})
@Injectable()
export class PromotionsState {
  constructor(
    private readonly _promotionService: PromotionService,
    private readonly _syneriseService: SyneriseService,
    private readonly _store: Store,
    private readonly _recaptchaV3Service: ReCaptchaV3Service,
  ) {}

  @Selector()
  public static userPromotionsHistory({ userPromotionsHistory }: PromotionsStateModel): IPromotionHistoryDataItem[] {
    return userPromotionsHistory;
  }

  @Selector()
  public static registeredProductId({ registeredProductId }: PromotionsStateModel): string {
    return registeredProductId;
  }

  @Selector()
  public static createdApplicationId({ createdApplicationId }: PromotionsStateModel): string {
    return createdApplicationId;
  }

  @Selector()
  public static loading({ loading }: PromotionsStateModel): boolean {
    return loading;
  }

  @Selector()
  public static productForm({ productForm }: PromotionsStateModel): INgxsFormModel<IProductFormModel> {
    return productForm;
  }

  @Selector()
  public static summaryUserForm({ summaryUserForm }: PromotionsStateModel): INgxsFormModel<IUserSummaryFormModel> {
    return summaryUserForm;
  }

  @Selector()
  public static addedProducts({ addedProducts }: PromotionsStateModel): Array<IAddedProduct> {
    return addedProducts;
  }

  @Selector()
  public static addedRegisteredProducts({
    addedRegisteredProducts,
  }: PromotionsStateModel): Array<IRegisteredProductDataItem> {
    return addedRegisteredProducts;
  }

  @Selector()
  public static activePromotions({ activePromotions }: PromotionsStateModel): IListResponseData<IPromotionDataItem> {
    return activePromotions;
  }

  @Selector()
  public static dedicatedPromotions({
    dedicatedPromotions,
  }: PromotionsStateModel): IListResponseData<IPromotionDataItem> {
    return dedicatedPromotions;
  }

  @Selector()
  public static promotionsDetails({ promotionsDetails }: PromotionsStateModel): {
    [promotionId: number]: IPromotionDetails;
  } {
    return promotionsDetails;
  }

  private static _buildWarrantyCard(warrantyCardFile: File, productFormModel: IProductFormModel): IWarrantyCard {
    const { serial } = productFormModel;
    return {
      file: warrantyCardFile,
      serial,
      document_type: DocumentTypeEnum.WARRANTY_CARD,
    };
  }

  private static _buildOtherDocument(warrantyCardFile: File): IOtherDocument {
    return {
      file: warrantyCardFile,
      document_type: DocumentTypeEnum.OTHER,
    };
  }

  private static _buildInvoice(invoiceFile: File, productFormModel: IProductFormModel): IInvoice {
    const {
      shop_type_select,
      shop_stationary_select,
      shop_online_select,
      purchase_date,
      serial,
      product,
      ecode_other_www,
      ecode_other_name,
      shop_stationary_region,
      ecode_other_city,
      ecode_other_address,
      ecode_other_zip_code,
    } = productFormModel;

    const anotherECodeValue = -1;
    const addECode =
      (shop_type_select === ECodeTypeEnum.STATIONARY && shop_stationary_select === anotherECodeValue) ||
      (shop_type_select === ECodeTypeEnum.WWW && shop_online_select === anotherECodeValue)
        ? 'true'
        : 'false';
    const _invoice: IInvoice = {
      file: invoiceFile,
      purchase_date,
      serial,
      product_id: product,
      document_type: DocumentTypeEnum.PURCHASE,
      add_ecode: addECode,
    };
    if (addECode === 'false') {
      if (shop_type_select === ECodeTypeEnum.WWW) {
        _invoice.ecode_id = shop_online_select;
      } else if (shop_type_select === ECodeTypeEnum.STATIONARY) {
        _invoice.ecode_id = shop_stationary_select;
      }
    } else if (addECode === 'true') {
      if (shop_type_select === ECodeTypeEnum.WWW) {
        _invoice.ecode = {
          name: ecode_other_www,
          type: ECodeTypeEnum.WWW,
        };
      } else if (shop_type_select === ECodeTypeEnum.STATIONARY) {
        _invoice.ecode = {
          name: ecode_other_name,
          state_id: shop_stationary_region,
          city: ecode_other_city,
          street: ecode_other_address,
          zip_code: ecode_other_zip_code,
          type: ECodeTypeEnum.STATIONARY,
        };
      }
    }
    return _invoice;
  }

  @Action(PromotionsStateFindById)
  public findById(
    { patchState, getState }: StateContext<PromotionsStateModel>,
    { payload: { promotionId } }: PromotionsStateFindById,
  ): Observable<IPromotionDetails> {
    patchState({ addedRegisteredProducts: [], addedProducts: [] });
    const { promotionsDetails } = getState();
    if (promotionsDetails[promotionId]) {
      return of(promotionsDetails[promotionId]);
    }
    patchState({ loading: true });
    return this._promotionService.findById(promotionId).pipe(
      tap((promotionDetails) => {
        const _promotionsDetails = { ...promotionsDetails };
        _promotionsDetails[promotionId] = promotionDetails;
        patchState({ promotionsDetails: _promotionsDetails });
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(PromotionsStateAddProduct)
  public addProduct(
    { patchState, getState }: StateContext<PromotionsStateModel>,
    { payload: { invoiceFile, warrantyCardFile, otherDocumentFile, productDataForDisplay } }: PromotionsStateAddProduct,
  ): void {
    const {
      productForm: { model },
    } = getState();

    const _invoice: IInvoice = PromotionsState._buildInvoice(invoiceFile.fileItem._file, model);
    const _warrantyCard: IWarrantyCard = PromotionsState._buildWarrantyCard(warrantyCardFile.fileItem._file, model);
    const _otherDocument = otherDocumentFile
      ? PromotionsState._buildOtherDocument(otherDocumentFile.fileItem._file)
      : null;
    patchState({
      addedProducts: [
        ...getState().addedProducts,
        {
          invoice: _invoice,
          warrantyCard: _warrantyCard,
          otherDocument: _otherDocument || null,
          productDataForDisplay,
          productFormModel: model,
          invoiceFile: [invoiceFile],
          warrantyCardFile: [warrantyCardFile],
          otherDocumentFile: otherDocumentFile ? [otherDocumentFile] : null,
        },
      ],
    });
  }

  @Action(PromotionsStateAddRegisteredProduct)
  public addRegisteredProduct(
    { patchState, getState }: StateContext<PromotionsStateModel>,
    { payload: { registeredProductDataItem } }: PromotionsStateAddRegisteredProduct,
  ): void {
    const { addedRegisteredProducts } = getState();
    patchState({
      addedRegisteredProducts: [...addedRegisteredProducts, registeredProductDataItem],
    });
  }

  @Action(PromotionsStateAddApplication)
  public addApplication(
    { patchState, getState }: StateContext<PromotionsStateModel>,
    { payload: { promotionId, user, agreements, description } }: PromotionsStateAddApplication,
  ): Observable<unknown> {
    const {
      addedProducts,
      addedRegisteredProducts,
      summaryUserForm: { model },
      promotionsDetails,
    } = getState();

    const _buildAddApplicationDto = () => {
      const { mobile, zip_code, city, house_number, flat_number, street, firstname, lastname, email } = model;
      const address = {
        city,
        flat_number,
        street,
        house_number,
        zip_code,
      };
      if (flat_number) {
        address.flat_number = flat_number === 'null' ? null : flat_number;
      }

      const { number, dialCode } = mobile as IMobileInfo;
      const documents: (IInvoice | IWarrantyCard | IOtherDocument)[] = [
        ...addedProducts.map(
          (value) =>
            ({
              ...value.invoice,
              amica_products: [
                {
                  price: '0',
                  serial: value.invoice.serial,
                  product_id: value.invoice.product_id,
                },
              ],
              product_id: undefined,
            }) as IInvoice,
        ),
        ...addedProducts.map((value) => value.warrantyCard),
      ];
      if (addedProducts.some((value) => value.otherDocument)) {
        documents.push(...addedProducts.map((value) => value.otherDocument));
      }
      const addApplicationDto: IAddApplicationDto = {
        firstname,
        lastname,
        email: email || user?.login,
        mobile: mobile ? number.replace(/-/g, '') : user?.mobile,
        mobile_prefix: mobile ? dialCode.replace('+', '') : user?.mobile_prefix,
        marketing_agreement: agreements?.marketing_agreement ? 'true' : 'false',
        privacy_agreement: 'true',
        regulation_agreement: 'true',
        service_agreement: agreements?.service_agreement ? 'true' : 'false',
        address,
        documents: documents,
        registered_products: addedRegisteredProducts.map((value) => value.id),
      };
      if (description) {
        addApplicationDto.description = description;
      }
      return addApplicationDto;
    };
    const addApplicationDto: IAddApplicationDto = _buildAddApplicationDto();

    if (!addApplicationDto) {
      return throwError(() => new Error('Application dto is required!'));
    } else if (!promotionId) {
      return throwError(() => new Error('Promotion id is required!'));
    }

    const _sendSynFormData = () => {
      const { mobile, address, firstname, lastname, email, documents } = addApplicationDto;

      const { name, created_at, description, end_application_date, end_date, start_date, promotion_url } =
        promotionsDetails[promotionId];

      const _buildSynProductsByPurchaseDocuments = (documents: Array<IInvoice | IWarrantyCard | IOtherDocument>) => {
        return documents
          .filter((value) => value.document_type === DocumentTypeEnum.PURCHASE)
          .map((value) => {
            const invoice = value as IInvoice;
            const productId = invoice.amica_products[0].product_id;

            const synRegisterProductDto: ISynRegisterProductDto = {
              purchase_date: invoice.purchase_date,
              product_id: productId,
              product_selected: promotionsDetails[promotionId].product_full_list.find(
                (productByPromotion) => productByPromotion.id === productId,
              ),
              product_serial: invoice.amica_products[0].serial,
              ecode_added_www: null,
              ecode_added_stationary: null,
              ecode_www: null,
              ecode_stationary: null,
            };

            if (invoice?.add_ecode === 'true') {
              if (invoice.ecode.type === ECodeTypeEnum.STATIONARY) {
                synRegisterProductDto.ecode_added_stationary = invoice.ecode;
              } else {
                synRegisterProductDto.ecode_added_www = Utils.pick(invoice.ecode, 'name', 'type');
              }
            } else {
              const cityECodes: { [p: string]: IECodeDataItem[] } = this._store.selectSnapshot(ECodesState.cityECodes);
              synRegisterProductDto.ecode_stationary = Object.keys(cityECodes)
                .reduce((previous, key) => previous.concat(cityECodes[key]), [] as IECodeDataItem[])
                .find((eCode) => eCode.id === invoice.ecode_id);
              synRegisterProductDto.ecode_www = this._store
                .selectSnapshot(ECodesState.onlineECodes)
                .find((eCode) => eCode.id === invoice.ecode_id);
            }
            return synRegisterProductDto;
          });
      };

      const dto: ISynRegisterDto = {
        firstname: firstname || null,
        lastname: lastname || null,
        mobile,
        mobile_prefix: addApplicationDto.mobile_prefix,
        email: email || null,
        registerBy: 'promotion',
      };
      if (agreements.newsletterAgreement) {
        dto.newsletterAgreement = 'enabled';
      }
      this._syneriseService.sendFormData(SyneriseActionEnum.REGISTER_BY_PROMOTION, dto);

      const synPromotionApplicationDto: ISynPromotionApplicationDto = {
        address,
        email,
        firstname,
        lastname,
        mobile,
        products: _buildSynProductsByPurchaseDocuments(documents),
        promotion: {
          created_at,
          description,
          end_application_date,
          end_date,
          id: promotionId,
          name,
          promotion_url,
          start_date,
        },
      };
      if (agreements.newsletterAgreement) {
        synPromotionApplicationDto.newsletterAgreement = 'enabled';
      }
      this._syneriseService.sendFormData(SyneriseActionEnum.SEND_PROMOTION_APPLICATION, synPromotionApplicationDto);
    };
    patchState({ loading: true });
    return this._recaptchaV3Service.execute('importantAction').pipe(
      timeout({ first: 5000, with: () => throwError(() => 'timeout') }),
      concatMap((recaptchaKey: string) => {
        return this._promotionService.addApplication(promotionId, addApplicationDto, recaptchaKey).pipe(
          tap((createdApplicationId) => {
            patchState({ createdApplicationId, addedProducts: [], addedRegisteredProducts: [] });
            _sendSynFormData();
          }),
          finalize(() => {
            patchState({ loading: false });
          }),
        );
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(PromotionsStateSendWarranty)
  public sendWarranty(
    { patchState, getState }: StateContext<PromotionsStateModel>,
    { payload: { promotionId, user, agreements, description, questions } }: PromotionsStateSendWarranty,
  ): Observable<unknown> {
    const {
      addedProducts,
      summaryUserForm: { model },
    } = getState();

    const _buildSendWarrantyDto = () => {
      const { mobile, zip_code, city, house_number, flat_number, street, firstname, lastname, email, iban } = model;
      const { number, dialCode } = mobile as IMobileInfo;
      const addApplicationDto: ISendWarrantyDto = {
        firstname,
        lastname,
        email: email || user?.login,
        phone: mobile ? number.replace(/-/g, '') : user?.mobile,
        phonePrefix: mobile ? dialCode.replace('+', '') : user?.mobile_prefix,
        street,
        houseNumber: house_number,
        flatNumber: flat_number || null,
        zipCode: zip_code,
        city,
        serialNumber: addedProducts[0].invoice.serial,
        productCategory: addedProducts[0].productFormModel.category,
        productType: addedProducts[0].productDataForDisplay.symbol,
        documentWarranty: addedProducts[0].warrantyCard.file,
        documentWarrantyInstaller: addedProducts[0].otherDocument.file,
        documentPurchase: addedProducts[0].invoice.file,
        agreementOne: agreements?.agreementOne,
        agreementOneText: agreements?.agreementOneText,
        agreementTwo: agreements?.agreementTwo,
        agreementTwoText: agreements?.agreementTwoText,
        description: description || null,
        question1: questions.question1,
        question2: questions.question2,
        question3: questions.question3,
        question4: questions.question4,
        question5: questions.question5,
        bankAccount: iban,
        purchaseDate: addedProducts[0].invoice.purchase_date,
      };
      if (addedProducts[0].invoice.ecode_id) {
        addApplicationDto.eCode = addedProducts[0].invoice.ecode_id;
      } else if (addedProducts[0].invoice.add_ecode === 'true') {
        addApplicationDto.newECode = {
          city: addedProducts[0].invoice.ecode.city,
          state_id: addedProducts[0].invoice.ecode.state_id,
          type: addedProducts[0].invoice.ecode.type,
          name: addedProducts[0].invoice.ecode.name,
          street: addedProducts[0].invoice.ecode.street,
          zip_code: addedProducts[0].invoice.ecode.zip_code,
        };
      }
      return addApplicationDto;
    };
    const sendWarrantyDto: ISendWarrantyDto = _buildSendWarrantyDto();

    if (!sendWarrantyDto) {
      return throwError(() => new Error('Send warranty dto is required!'));
    } else if (!promotionId) {
      return throwError(() => new Error('Promotion id is required!'));
    }

    const _sendSynFormData = () => {
      const { phone, firstname, lastname, email } = sendWarrantyDto;
      const dto: ISynRegisterDto = {
        firstname: firstname || null,
        lastname: lastname || null,
        mobile: phone,
        mobile_prefix: sendWarrantyDto.phonePrefix,
        email: email || null,
        registerBy: 'promotion',
      };
      if (agreements.newsletterAgreement) {
        dto.newsletterAgreement = 'enabled';
      }
      this._syneriseService.sendFormData(SyneriseActionEnum.REGISTER_BY_PROMOTION, dto);
    };
    patchState({ loading: true });
    return this._recaptchaV3Service.execute('importantAction').pipe(
      timeout({ first: 5000, with: () => throwError(() => 'timeout') }),
      concatMap((recaptchaKey: string) => {
        return this._promotionService.sendWarranty(sendWarrantyDto, recaptchaKey).pipe(
          tap((createdApplicationId) => {
            patchState({ createdApplicationId, addedProducts: [], addedRegisteredProducts: [] });
            _sendSynFormData();
          }),
          finalize(() => {
            patchState({ loading: false });
          }),
        );
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }
  @Action(PromotionsStateCompetitionColorSubmit)
  public competitionColorSubmit(
    { patchState, getState }: StateContext<PromotionsStateModel>,
    {
      payload: { promotionId, user, comment, mesh, screen, template, agreement },
    }: PromotionsStateCompetitionColorSubmit,
  ): Observable<unknown> {
    const {
      summaryUserForm: { model },
    } = getState();

    const _buildCampaignColorSubmitDto = () => {
      const { mobile, zip_code, city, house_number, flat_number, street, firstname, lastname, email, link } = model;
      const { number, dialCode } = mobile as IMobileInfo;
      const addApplicationDto: ICampaignColorSubmitDto = {
        firstname,
        lastname,
        email: email || user?.login,
        phone: mobile ? number.replace(/-/g, '') : user?.mobile,
        phonePrefix: mobile ? dialCode.replace('+', '') : user?.mobile_prefix,
        street,
        houseNumber: house_number,
        flatNumber: flat_number || null,
        zipCode: zip_code,
        city,
        comment,
        link,
        mesh: mesh.fileItem._file,
        screen: screen.fileItem._file,
        template: template.fileItem._file,
        agreement,
      };
      return addApplicationDto;
    };
    const campaignColorSubmitDto: ICampaignColorSubmitDto = _buildCampaignColorSubmitDto();

    if (!campaignColorSubmitDto) {
      return throwError(() => new Error('Campaign color submit dto is required!'));
    } else if (!promotionId) {
      return throwError(() => new Error('Promotion id is required!'));
    }

    const _sendSynFormData = () => {
      const { phone, firstname, lastname, email } = campaignColorSubmitDto;
      const dto: ISynRegisterDto = {
        firstname: firstname || null,
        lastname: lastname || null,
        mobile: phone,
        mobile_prefix: campaignColorSubmitDto.phonePrefix,
        email: email || null,
        registerBy: 'promotion',
      };
      this._syneriseService.sendFormData(SyneriseActionEnum.REGISTER_BY_PROMOTION, dto);
    };
    patchState({ loading: true });
    return this._recaptchaV3Service.execute('importantAction').pipe(
      timeout({ first: 5000, with: () => throwError(() => 'timeout') }),
      concatMap((recaptchaKey: string) => {
        return this._promotionService.competitionColorSubmit(campaignColorSubmitDto, recaptchaKey).pipe(
          tap((createdApplicationId) => {
            patchState({ createdApplicationId, addedProducts: [], addedRegisteredProducts: [] });
            _sendSynFormData();
          }),
          finalize(() => {
            patchState({ loading: false });
          }),
        );
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(PromotionsStateCompetitionColorVote)
  public competitionColorVote(
    { patchState }: StateContext<PromotionsStateModel>,
    { payload: { voteImage } }: PromotionsStateCompetitionColorVote,
  ): Observable<unknown> {
    patchState({ loading: true });
    return this._promotionService.competitionColorVote(voteImage).pipe(
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(PromotionsStateRegisterProduct)
  public registerProduct(
    { patchState, getState }: StateContext<PromotionsStateModel>,
    { payload: { invoiceFile, warrantyCardFile, formGroup, productDataForDisplay } }: PromotionsStateRegisterProduct,
  ): Observable<unknown> {
    const {
      productForm: { model },
    } = getState();

    const invoice: IInvoice = PromotionsState._buildInvoice(invoiceFile.fileItem._file, model);
    delete invoice.amica_products;
    delete invoice.document_type;
    const warrantyCard: IWarrantyCard = PromotionsState._buildWarrantyCard(warrantyCardFile.fileItem._file, model);
    delete warrantyCard.document_type;
    const registerProductDto: IRegisterProductDto = {
      purchase_document: invoice,
      warranty_card: warrantyCard,
    };
    const _sendSynFormData = (registerProductDto: IRegisterProductDto) => {
      const { purchase_date, product_id, serial, add_ecode, ecode, ecode_id } = registerProductDto.purchase_document;

      const synRegisterProductDto: ISynRegisterProductDto = {
        purchase_date,
        product_serial: serial,
        product_id,
        ecode_added_stationary: null,
        ecode_stationary: null,
        ecode_www: null,
        ecode_added_www: null,
        product_selected: {
          id: product_id,
          name: productDataForDisplay.name,
          category: productDataForDisplay.category,
          symbol: productDataForDisplay.symbol,
        },
      };
      if (add_ecode === 'true') {
        if (ecode.type === ECodeTypeEnum.STATIONARY) {
          synRegisterProductDto.ecode_added_stationary = ecode;
        } else {
          synRegisterProductDto.ecode_added_www = Utils.pick(ecode, 'name', 'type');
        }
      } else {
        const cityECodes: { [p: string]: IECodeDataItem[] } = this._store.selectSnapshot(ECodesState.cityECodes);
        synRegisterProductDto.ecode_stationary = Object.keys(cityECodes)
          .reduce((previous, key) => previous.concat(cityECodes[key]), [] as IECodeDataItem[])
          .find((eCode) => eCode.id === ecode_id);
        synRegisterProductDto.ecode_www = this._store
          .selectSnapshot(ECodesState.onlineECodes)
          .find((eCode) => eCode.id === ecode_id);
      }
      this._syneriseService.sendFormData(SyneriseActionEnum.REGISTER_PRODUCT, synRegisterProductDto);
    };

    patchState({ loading: true, registeredProductId: null });
    return this._promotionService.registerProduct(registerProductDto).pipe(
      catchError((error) => {
        if (error?.error?.code === 400) {
          Utils.setErrorsAndScrollToThem(error?.error?.errors?.children, formGroup);
          formGroup.updateValueAndValidity();
        }
        patchState({ loading: false });
        return throwError(() => error);
      }),
      tap((registeredProductId) => {
        _sendSynFormData(registerProductDto);
        patchState({ loading: false, registeredProductId });
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(PromotionsStateFetchActivePromotions)
  public fetchActivePromotions({
    patchState,
    getState,
  }: StateContext<PromotionsStateModel>): Observable<IListResponseData<IPromotionDataItem>> {
    const { activePromotions } = getState();
    if (activePromotions) {
      return of(activePromotions);
    }
    const promotionsDto: IPromotionsDto = {
      status: PromotionStatusEnum.ACTIVE,
      perPage: 9999,
    };
    patchState({ loading: true });
    return this._promotionService.getPromotions(promotionsDto).pipe(
      tap((activePromotions) => {
        patchState({
          loading: false,
          activePromotions: {
            ...activePromotions,
            data: activePromotions.data.filter(
              (promotion) => new Date(promotion.start_date).getTime() <= new Date().getTime(),
            ),
          },
        });
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(PromotionsStateFetchDedicatedPromotions)
  public fetchDedicatedActivePromotions({
    patchState,
    getState,
  }: StateContext<PromotionsStateModel>): Observable<IListResponseData<IPromotionDataItem>> {
    const { dedicatedPromotions } = getState();
    if (dedicatedPromotions) {
      return of(dedicatedPromotions);
    }
    const promotionsDto: IPromotionsDto = {
      status: PromotionStatusEnum.ACTIVE,
      perPage: 9999,
    };
    patchState({ loading: true });
    return this._promotionService.getDedicatedPromotions(promotionsDto).pipe(
      tap((_dedicatedPromotions) => {
        patchState({ loading: false, dedicatedPromotions: _dedicatedPromotions });
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(PromotionsStateFetchUserHistoryPromotions)
  public fetchUserPromotions({
    patchState,
  }: StateContext<PromotionsStateModel>): Observable<IPromotionHistoryDataItem[]> {
    patchState({ loading: true });
    return this._promotionService.getUserHistoryPromotions().pipe(
      tap((userPromotions) => {
        patchState({ loading: false, userPromotionsHistory: userPromotions });
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(PromotionsStateDeleteProduct)
  public deleteProduct(
    { patchState, getState }: StateContext<PromotionsStateModel>,
    { payload: { index } }: PromotionsStateDeleteProduct,
  ): void {
    const copy = [...getState().addedProducts];
    copy.splice(index, 1);
    patchState({
      addedProducts: copy,
    });
  }

  @Action(PromotionsStateDeleteRegisteredProduct)
  public deleteRegisteredProduct(
    { patchState, getState }: StateContext<PromotionsStateModel>,
    { payload: { index } }: PromotionsStateDeleteRegisteredProduct,
  ): void {
    const copy = [...getState().addedRegisteredProducts];
    copy.splice(index, 1);
    patchState({
      addedRegisteredProducts: copy,
    });
  }
}
